// Controller for the voting statistics pages

module.exports = ['$rootScope', '$scope', '$http', '$state', 'popups', '$stateParams', '$window', '$interval', 'appState', function ($rootScope, $scope, $http, $state, popups, $stateParams, $window, $interval, appState) {

    // Test if a specific session is active and assign the session ID
    var sessionId = $stateParams.sessionId;
    if (!sessionId || isNaN(sessionId)) {
        $state.go('el.sessions');
        return;
    }

    // Scope variables
    $scope.msg = '';
    $scope.rollInfo = {};
    $scope.lastCandidates = [];
    $scope.results = {
        Start: null,
        End: new Date()
    };
    $scope.results.End.setMinutes(0, 0, 0);

    // Get the session settings from the database
    function getSessDetails() {
        $scope.msg = '';
        $http.get('/api/getAdminData/' + sessionId, null).then(function (resp) {
            $scope.SessionSettings = resp.data.Details;
            $scope.results.Start = new Date($scope.SessionSettings.Start_Date);
            $rootScope.CurrentSession = $scope.SessionSettings.Name;
            $rootScope.CurrentSessionImage = null;
            if ($scope.SessionSettings.Logo != null && $scope.SessionSettings.Logo != '') {
                $rootScope.CurrentSessionImage = $scope.SessionSettings.Logo;
            }
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
            }
        });
    }

    function getOrigRoll(callback) {
        $http.get('/api/getRollAdmin/' + sessionId + '/Original').then(function (resp) {
            $scope.rollInfo.Original = resp.data.RowNr;
            callback(true);
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    function getSuppRoll(callback) {
        $http.get('/api/getRollAdmin/' + sessionId + '/Supplemental').then(function (resp) {
            $scope.rollInfo.Supplemental = resp.data.RowNr;
            $scope.rollInfo.TotalRoll = $scope.rollInfo.Original + $scope.rollInfo.Supplemental;
            callback(true);
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    function getVotes() {
        $http.get('/api/getVotesAdmin/' + sessionId).then(function (resp) {
            var req = 0; // 0% Requirement
            $scope.rollInfo.Votes = resp.data.RowNr;
            $scope.rollInfo.Percent = Math.round($scope.rollInfo.Votes / $scope.rollInfo.TotalRoll * 10000) / 100 || 0;
            var requirement = Math.ceil($scope.rollInfo.TotalRoll * req);
            var newVotedData = [];
            if ($scope.rollInfo.Votes >= requirement) { // If there are more votes than what is required, show only the votes and remaining
                $scope.rollInfo.Remaining = $scope.rollInfo.TotalRoll - $scope.rollInfo.Votes;
                newVotedData = [
                    {
                        key: "Remaining (" + $scope.rollInfo.Remaining + ")",
                        y: $scope.rollInfo.Remaining,
                        colour: "#1269c7"
                    },
                    {
                        key: "Voted (" + $scope.rollInfo.Votes + ")",
                        y: $scope.rollInfo.Votes,
                        colour: "#175d2d"
                    }
                ];
            } else { // If there are less votes than what is required, show the votes, required and remaining
                $scope.rollInfo.Remaining = $scope.rollInfo.TotalRoll - $scope.rollInfo.Votes - requirement;
                var required = requirement - $scope.rollInfo.Votes;
                newVotedData = [
                    {
                        key: "Remaining (" + $scope.rollInfo.Remaining + ")",
                        y: $scope.rollInfo.Remaining,
                        colour: "#1269c7"
                    },
                    {
                        key: "Voted (" + $scope.rollInfo.Votes + ")",
                        y: $scope.rollInfo.Votes,
                        colour: "#175d2d"
                    },
                    {
                        key: "Required (" + required + ")",
                        y: required,
                        colour: "#e0301e"
                    }
                ];
            }
            
            $scope.Voteddata = newVotedData;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                $scope.msg = err.data.message;
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    // Get the number of users on the voters rolls as well as the votes cast on the database for display as well as to create the Voted chart
    function getVotersandVotes () {
        getOrigRoll(function (cont) {
            getSuppRoll(function (cont) {
                getVotes();
            });
        });
    }

    // Options for the Voted chart
    $scope.VotedOptions = {
        // Chart options
        chart: {
            type: 'pieChart',
            height: 270,
            x: function (d) { return d.key; },
            y: function (d) { return d.y; },
            showLabels: true,
            duration: 500,
            labelThreshold: 0.01,
            //labelSunbeamLayout: true,
            donutLabelsOutside: true,
            legend: {
                margin: {
                    top: 5,
                    right: 35,
                    bottom: 5,
                    left: 0
                },
                rightAlign:false
            },
            margin: {
                top: null,
                right: null,
                bottom: null,
                left: 20
            },
            color: function (d) {
                return d.colour;
            }
        },

        // title options
        title: {
            enable: true,
            text: 'Voter Turnout'
        }
    };

    // To get the votes on a timebasis for the timechart
    function getTimeVotes() {
        $scope.msg = '';
        $http.get('/api/getTimeVotes/' + sessionId).then(function (resp) {
            $scope.newtimeVoteData = resp.data;
            var newObject = {};
            newObject.key = 'Votes per timeslot';
            newObject.color = '#eb8c00';
            newObject.values = [];
            for (var i = 23; i >= 0; i--) {
                newObject.values.push({
                    x: 23 - i,
                    y: $scope.newtimeVoteData[i].Total,
                    label: new Date($scope.newtimeVoteData[i].Bracket).toLocaleTimeString().slice(0, -6)
                });
            }
            var newArray = [];
            newArray.push(newObject);
            $scope.timeData = newArray;

        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
                $scope.msg = err.data.message;
            }
        });
    }


    $scope.timeOptions = {
        chart: {
            type: 'lineChart',
            height: 270,
            margin: {
                top: 20,
                right: 20,
                bottom: 40,
                left: 55
            },
            x: function (d) { return d.x; },
            y: function (d) { return d.y; },
            useInteractiveGuideline: false,
            showLegend: false,
            dispatch: {
                stateChange: function (e) { console.log("stateChange"); },
                changeState: function (e) { console.log("changeState"); },
                tooltipShow: function (e) { console.log("tooltipShow"); },
                tooltipHide: function (e) { console.log("tooltipHide"); }
            },
            xAxis: {
                axisLabel: 'Time (30 minute brackets)',
                tickFormat: function (d) {
                    var label = $scope.timeData[0].values[d].label;
                    return label;
                }
            },
            yAxis: {
                axisLabel: 'Votes',
                axisLabelDistance: -10
            },
            callback: function (chart) {
                console.log("!!! lineChart callback !!!");
            }
        },
        title: {
            enable: true,
            text: 'Timing of Votes'
        }
    };

    function getLastVoters() {
        $scope.msg = '';
        $scope.lastCandidates = [];
        $http.get('/api/getLastVoterList/' + sessionId).then(function (resp) {
            $scope.lastCandidates = resp.data;
        }).catch(function (err) {
            if (err && err.data && err.data.message) {
                popups.alertPopup(err.data.message, 'Error');
            }
        });
    }

    // To open the voters roll statistics page in a new window
    $scope.openExternal = function () {
        $rootScope.cancelIdleTimer(); // Cancelling timer to ensure logout does not occur while monitoring is in progress.
        var url = $state.href('eom.rollstatsexternal', { sessionId: sessionId });
        $window.open(url, '_blank');
    };

    // Test if the user has access to the interim voting
    $scope.hasAccess = function () {
        var station = sessionId;
        var type = 'Results_View';
        var found = false;
        if (appState.isAdmin) {
            return true;
        } else {
            var userAccess = appState.access;
            userAccess.forEach(function (access) {
                if (access.Session == station && access[type] == 1) {
                    found = true;
                }
            });
            return found;
        }
    };

    $scope.openAnonymous = function () {
        var dataurl = encodeURI(JSON.stringify($scope.results));
        var url = $state.href('eom.openanonymousresults', { sessionId: sessionId, data: dataurl });
        $window.open(url, '_blank');
    };

    // Set up a recurring function to get the data and update the view
    function startUpdates() {
        var intervalseconds = 30;
        $scope.getDataInterval = null;
        $scope.getDataInterval = $interval(function () {
            getVotersandVotes();
            getTimeVotes();
            getLastVoters();
        }, 1000 * intervalseconds);
    }

    function stopUpdates() {
        if ($scope.getDataInterval) {
            $interval.cancel($scope.getDataInterval);
        }
    }

    // On startup
    getVotersandVotes();
    getTimeVotes();
    getLastVoters();
    getSessDetails();

    if ($state.current.name == 'eom.rollstatsexternal') {
        $rootScope.cancelIdleTimer(); // Cancelling timer to ensure logout does not occur while monitoring is in progress.
        startUpdates();
    }

    // To stop the $interval function on destroying the element
    $scope.$on('$destroy', function () {
        stopUpdates();
        $rootScope.startIdleTimer();
    });
}];

